export default class S1F2 {
  generateReceiptContentS1F2 = async (transactionResponse, type, terminal = false) => {

    let content = [];

    console.log(transactionResponse);

    console.log(terminal);

    const breakLine = {
      Alignment: "Centred",
      EndOfLineFlag: true,
      Text: "--------------------------------",
    };

    const emptyLine = {
      Alignment: "Centred",
      EndOfLineFlag: true,
      Text: "",
    };

    // Get location details
    let location = transactionResponse.relationships.location.attributes;
    let phone_number = location.phone_number;
    let email = location.email;
    let vat_number = location.vat_number;

    let address1 = "";
    if (location.streetname && location.housenumber) {
      address1 = location.streetname + " " + location.housenumber;
    }

    let address2 = "";
    if (location.streetname && location.housenumber) {
      address2 = location.zipcode + " " + location.city;
    }

    if (terminal.client_name) {
      content.push({
        CharacterStyle: "Bold",
        Alignment: "Centred",
        EndOfLineFlag: true,
        Text: terminal.client_name,
      });
    }

    if (location.name) {
      content.push({
        CharacterStyle: "Bold",
        Alignment: "Centred",
        EndOfLineFlag: true,
        Text: location.name,
      });
    }

    if (address1) {
      content.push({
        CharacterStyle: "Bold",
        Alignment: "Centred",
        EndOfLineFlag: true,
        Text: address1,
      });
    }

    if (address2) {
      content.push({
        CharacterStyle: "Bold",
        Alignment: "Centred",
        EndOfLineFlag: true,
        Text: address2,
      });
    }

    if (phone_number) {
      content.push({
        CharacterStyle: "Bold",
        Alignment: "Centred",
        EndOfLineFlag: true,
        Text: phone_number,
      });
    }

    if (email) {
      content.push({
        CharacterStyle: "Bold",
        Alignment: "Centred",
        EndOfLineFlag: true,
        Text: email,
      });
    }

    if (vat_number) {
      content.push({
        CharacterStyle: "Bold",
        Alignment: "Centred",
        EndOfLineFlag: true,
        Text: "BTW: " + vat_number.toString(),
      });
    }

    let total = transactionResponse.attributes.total;
    let subtotal = transactionResponse.attributes.subtotal;
    let transactionLines =
      transactionResponse.relationships["lines_consolidated"];

    let table_name;
    let clerk_id;
    let time;

    clerk_id = transactionResponse.attributes.clerk_id;

    console.log(transactionResponse)

    console.log(clerk_id);

    if (type === "table") {
      table_name = transactionResponse.attributes.name;
    } else if (type === "transaction") {
      table_name = transactionResponse.attributes.table_id;
    }

    time = transactionResponse.timestamp || transactionResponse.attributes.timestamp;

    content.push(emptyLine);
    if (table_name || clerk_id) {
      content.push(emptyLine);
    }

    console.log(table_name);

    if (table_name) {
      if (type === "table") {
        content.push({
          CharacterStyle: "Bold",
          Alignment: "Left",
          EndOfLineFlag: false,
          Text: table_name,
        });
      } else if (type === "transaction") {
        content.push({
          to_process: "Table",
          CharacterStyle: "Bold",
          Alignment: "Left",
          EndOfLineFlag: false,
          Text: table_name,
        });
      }
    }

    if (clerk_id) {
      content.push({
        to_process: "Clerk",
        Alignment: "Right",
        EndOfLineFlag: true,
        Text: clerk_id,
      });
    }

    if (transactionResponse.relationships.subtotal) {
      content.push({
        Alignment: "Right",
        EndOfLineFlag: true,
        Text: transactionResponse.relationships.subtotal.attributes.name,
      });
    }

    content.push(emptyLine);
    if (table_name || clerk_id) {
      content.push(emptyLine);
    }

    if (transactionLines) {
      // Add TRANSACTIONLINES
      transactionLines.forEach(function (elem) {
        let name =
          Math.round(elem.attributes.quantity).toString() +
          " x " +
          elem.attributes.description;
        content.push({
          Alignment: "Left",
          EndOfLineFlag: false,
          Text: name,
        });
        content.push({
          Alignment: "Right",
          EndOfLineFlag: true,
          Text: elem.attributes.total.toFixed(2).toString(),
        });
      });

      content.push(breakLine);
      content.push(emptyLine);

      // -----------------------------------------------------]
      //Add TOTAL Excl VAT


      if (type !== 'table') {
        content.push({
          Alignment: "Left",
          EndOfLineFlag: false,
          // Text: "TOTAL (excl. VAT)",
          Text: "TOTAL (excl. VAT)",
        });

        content.push({
          Alignment: "Right",
          EndOfLineFlag: true,
          Text: subtotal.toFixed(2).toString(),
        });

        // ------------------------------------------------------
        // Add VAT
        let taxes_small = transactionResponse.relationships.taxes["6%"];
        let taxes_big = transactionResponse.relationships.taxes["21%"];

        //VAT SMALL
        if (taxes_small) {
          content.push({
            Alignment: "Left",
            EndOfLineFlag: false,
            Text: "VAT (6%)",
          });

          content.push({
            Alignment: "Right",
            EndOfLineFlag: true,
            Text: parseFloat(taxes_small).toFixed(2).toString(),
          });
        }

        //VAT BIG
        if (taxes_big) {
          content.push({
            Alignment: "Left",
            EndOfLineFlag: false,
            Text: "VAT (21%)",
          });

          content.push({
            Alignment: "Right",
            EndOfLineFlag: true,
            Text: parseFloat(taxes_big).toFixed(2).toString(),
          });
        }


        content.push(breakLine);
        content.push(emptyLine);

        // ------------------------------------------------------
        // Add TOTAL
        content.push({
          CharacterStyle: "Bold",
          Alignment: "Left",
          EndOfLineFlag: false,
          Text: "TOTAL (incl. VAT)",
        });

        content.push({
          CharacterStyle: "Bold",
          Alignment: "Right",
          EndOfLineFlag: true,
          Text: total.toFixed(2).toString(),
        });
      } else {
        content.push({
          CharacterStyle: "Bold",
          Alignment: "Left",
          EndOfLineFlag: false,
          Text: "TOTAL",
        });

        content.push({
          CharacterStyle: "Bold",
          Alignment: "Right",
          EndOfLineFlag: true,
          Text: total.toFixed(2).toString(),
        });
      }


      // ------------------------------------------------------

      // Add PAYMENT
      if (transactionResponse.relationships.payments) {
        transactionResponse.relationships.payments.forEach(function (elem) {
          content.push({
            CharacterStyle: "Bold",
            Alignment: "Left",
            EndOfLineFlag: false,
            Text: elem.attributes.paymenttype,
          });

          content.push({
            CharacterStyle: "Bold",
            Alignment: "Right",
            EndOfLineFlag: true,
            Text: elem.attributes.amount.toFixed(2).toString(),
          });
        });
      }

      // ------------------------------------------------------

      content.push(breakLine);
      content.push(emptyLine);

      if (time) {
        content.push({
          // CharacterStyle: "Bold",
          Alignment: "Centred",
          EndOfLineFlag: true,
          Text: time ? time.toString() : '',
        });
      }


      content.push(emptyLine);
      content.push(emptyLine);
    }

     // print receipt_reference
     if (terminal && terminal.receipt_reference && transactionResponse.attributes[terminal.receipt_reference]) {
      content.push({
        CharacterStyle: "Bold",
        Alignment: "Centred",
        EndOfLineFlag: true,
        Text: transactionResponse.attributes[terminal.receipt_reference],
      });
    }


    content.push(emptyLine);
    content.push(emptyLine);

    console.log(content);
    console.log(JSON.stringify(content, null, 2));


    return content;
  };
}
