<template>
    <modal-manager-login ref="managerlogin" @loggedin="printReport()" />
    <modal-mail-receipt ref="mailreceipt" mode="pos" :transaction_prop="transaction_prop" />

    <a class="cursor-pointer text-white" @click="open()">
        <svg class="h-6 h-6" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
        </svg>
    </a>
    <div v-if="active" class="fixed z-10 inset-0 overflow-y-auto">
        <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 transition-opacity" @click="close()">
                <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
            <div id="modal" class="inline-block align-bottom rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                <div class="modal-content">

                    <modal-close @close="close()" />

                    <div class="sm:flex sm:items-start mb-5">
                        <div class="mt-3 text-center sm:mt-0 sm:text-left">
                            <h3 class="modal-title text-lg leading-6 font-bold text-2xl mb-1" id="modal-headline">
                                {{ $t("translations.components.modal-transactions.title") }}
                                <span v-if="transactions_total > 0">
                                    #{{ transactions_total }}
                                </span>
                            </h3>
                        </div>
                    </div>

                    <div class="flex justify-start items-center gap-4">
                        <!-- Date -->
                        <select
                            v-model="filters.date"
                            class="select appearance-none rounded bg-gray-100 py-1 px-2 block w-full focus:outline-none w-[240px]"
                        >
                            <option value="" selected>Filter by time</option>
                            <option value="30minute">Last 30 minutes</option>
                            <option value="1hour">Last hour</option>
                            <option value="4hour">Last 4 hours</option>
                            <option value="8hour">Last 8 hours</option>
                            <option value="12hour">Last 12 hours</option>
                            <option value="1day">Last day</option>
                        </select>

                        <payment-type-select v-model="filters.payment_type" />

                        <select
                            v-model="filters.table"
                            class="select appearance-none rounded bg-gray-100 py-1 px-2 block w-full focus:outline-none"
                        >
                            <option value="" selected>Selecteer tafel</option>
                            <option v-for="(table, index_table) in tables" :key="index_table" :value="table.id">
                                {{ table.name }}
                            </option>
                        </select>

                        <button
                            @click.prevent="resetFilter"
                            :disabled="!isResetFilterClickable"
                            class="w-8 h-8 inline-flex items-center justify-center rounded text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                            :class="{
                                'opacity-50 cursor-not-allowed': !isResetFilterClickable
                            }"
                          >
                              <span class="sr-only">Reset</span>
                              <svg class="w-5 h-5" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M4.85355 2.14645C5.04882 2.34171 5.04882 2.65829 4.85355 2.85355L3.70711 4H9C11.4853 4 13.5 6.01472 13.5 8.5C13.5 10.9853 11.4853 13 9 13H5C4.72386 13 4.5 12.7761 4.5 12.5C4.5 12.2239 4.72386 12 5 12H9C10.933 12 12.5 10.433 12.5 8.5C12.5 6.567 10.933 5 9 5H3.70711L4.85355 6.14645C5.04882 6.34171 5.04882 6.65829 4.85355 6.85355C4.65829 7.04882 4.34171 7.04882 4.14645 6.85355L2.14645 4.85355C1.95118 4.65829 1.95118 4.34171 2.14645 4.14645L4.14645 2.14645C4.34171 1.95118 4.65829 1.95118 4.85355 2.14645Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path>
                              </svg>
                        </button>
                    </div>

                    <div class="my-5 min-h-400 text-center" v-if="transactions.length == 0">
                        {{
                            $t("translations.components.modal-transactions.no-transactions-available")
                        }}
                    </div>

                    <div class="my-5" v-else>
                        <div class="overflow-y-auto" style="height:400px">
                            <div class="pb-12 relative" v-for="(transaction, index_transaction) in transactions" :key="index_transaction">
                                <div class="flex items-start py-1 px-2 font-bold grid grid-cols-10 mt-1 text-xs border-t">
                                    <div class="inline-flex flex flex-wrap justify-start col-span-7 flex-cols-7">
                                        <div class="col-span-2 pr-2">{{ transaction.attributes[terminal.receipt_reference] }}</div>
                                        <div class="col-span-5">{{ transaction.attributes.time }} {{ transaction.attributes.date }}</div>
                                    </div>
                                    <div class="col-span-1">
                                        <span v-if="transaction.attributes.status == 'canceled'" class="text-red-500">Geannuleerd</span>
                                        <span v-else-if="transaction.attributes.status == 'completed'" class="text-green-500">Voltooid</span>
                                    </div>
                                    <div class="col-span-2 text-right">{{
                                            vueNumberFormat(transaction.attributes.total)
                                        }}
                                    </div>
                                    <div class="absolute bottom-2 right-1">
                                        <div class="flex gap-1">
                                            <span class="flex justify-end rounded-sm" v-if="transaction.attributes.status == 'completed'">
                                                <a @click="mailReceipt(transaction)" class="button button-ghost button-small">
                                                    Bon mailen
                                                </a>
                                            </span>

                                            <span class="flex justify-end rounded-sm" v-if="transaction.attributes.status == 'completed'">
                                                <a @click="print(transaction.id)" class="button button-ghost button-small">
                                                    {{
                                                        $t("translations.components.modal-transactions.print")
                                                    }}
                                                </a>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div v-for="(line, index_line) in transaction.relationships.lines" :key="index_line" class="py-1 px-2 grid grid-cols-12 mt-1 text-xs">
                                    <div class="col-span-1"></div>
                                    <div class="col-span-1">{{
                                            parseFloat(line.attributes.quantity)
                                        }}
                                    </div>
                                    <div class="col-span-8">{{ line.attributes.description }}</div>
                                    <div class="col-span-2 text-right">{{
                                            vueNumberFormat(line.attributes.total)
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <a @click="canPrintReport()" class="button button-wide button-large button-filled">
                        {{ $t("translations.components.modal-transactions.x-reporting") }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PaymentTypeSelect from "@/components/mobilepos/PaymentTypeSelect.vue";
import axios from "axios";

// components
import ModalManagerLogin from "./ModalManagerLogin";
import ModalMailReceipt from "./ModalMailReceipt";
import ModalClose from "./ModalClose";

// print
import PrintReport from "../prints/report";
import PosHelper from "../mixins/pos.helper";

const DEFAULT_FILTERS = {
  date: "",
  table: "",
  payment_type: "",
  amount: null,
};

export default {
    components: {
        PaymentTypeSelect,
        ModalManagerLogin,
        ModalClose,
        ModalMailReceipt,
    },
    data() {
        return {
            active: false,
            transactions: [],
            transaction_prop: null,
            transactions_total: 0,
            tables: [],
            filters: {
              ...DEFAULT_FILTERS
            },
        };
    },

    mixins: [PosHelper],

    mounted() {
        this.$watch(
            () => this.filters,
            () => this.transactionGet(),
            { deep: true },
        );
    },

    methods: {
        open() {
            this.resetFilter();
            this.transactionGet();
            this.active = true;
        },

        close() {
            this.resetFilter();
            this.active = false;
        },

        mailReceipt(transaction) {
            this.transaction_prop = transaction;
            this.$refs.mailreceipt.open();
        },

        canPrintReport() {
            this.$refs.managerlogin.open();
        },

        async printReport() {
            await axios.get("/xreport", {}).then(response => {
                new PrintReport().print("xreport", response.data);
            }).catch(() => {
                this.$notify({
                    group: "notifications",
                    type: "error",
                    title: "Fout opgetreden",
                    text: "Oeps er ging iets fout..",
                }, 2000);
            });
        },

        async print(transaction_id) {
            this.printReceipt(transaction_id);
        },

        async transactionGet() {
            const params = { ...this.filters };

            await axios.get("/transactions/latest", { params }).then(response => {
                this.transactions = response.data.transactions;
                this.transactions_total = response.data.total;
                this.tables = response.data?.tables ?? [];
            }).catch(() => {
                this.$notify({
                    group: "notifications",
                    type: "error",
                    title: "Fout opgetreden",
                    text: "Oeps er ging iets fout..",
                }, 2000);
            });
        },

        resetFilter() {
          this.filters = {
            ...DEFAULT_FILTERS,
          };
        },
    },
    computed: {
      isResetFilterClickable() {
        return Object.keys(this.filters).some(key => this.filters[key] !== DEFAULT_FILTERS[key]);
      },

      terminal() {
            return this.$store.getters["terminal/terminal"];
        },
    },
};
</script>

<style scoped>
.min-h-400 {
    min-height: 400px;
}
</style>
