<template>
  <div
    v-if="active"
    class="fixed z-10 inset-0 overflow-y-auto"
  >
    <div
      class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        v-if="page != 'confirmation'"
        class="fixed inset-0 transition-opacity"
        @click="close()"
      >
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>
      <div
        v-else
        class="fixed inset-0 transition-opacity"
      >
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span
      >&#8203;
      <div
        id="modal"
        class="modal-credit inline-block align-bottom rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
        :class="terminal.wallettoken_default_scan"
      >
        <div class="modal-content">
          <modal-close
            v-if="page != 'confirmation'"
            @close="close()"
          />

          <!-- select cashfunction -->
          <div v-if="page == 'scancardnumber'">
            <div class="flex justify-between items-center mb-5">
              <div class="mt-3 text-center sm:mt-0 sm:text-left">
                <h3
                  class="modal-title text-lg leading-6 font-bold text-2xl"
                  id="modal-headline"
                >
                  <div>
                    {{
                      $t(
                        "translations.components.modal-wallet-payment.card-payment"
                      )
                    }}: <currency />{{ vueNumberFormat(amount) }}
                  </div>
                  <div class="mt-3 text-lg font-normal">
                    {{
                      $t(
                        "translations.components.modal-wallet-payment.scan-card-number"
                      )
                    }}
                  </div>
                </h3>
              </div>
            </div>

            <numpad
              @inputchanged="cardnumber = $event"
              v-if="terminal.wallettoken_default_scan !== 'nfc'"
              :allow-scanner="true"
            />

            <template v-else>
              <svg
                class="nfc-icon"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22 12C22 8.22876 22 6.34315 20.8284 5.17157C19.6569 4 17.7712 4 14 4H10C6.22876 4 4.34315 4 3.17157 5.17157C2 6.34315 2 8.22876 2 12C2 15.7712 2 17.6569 3.17157 18.8284C4.34315 20 6.22876 20 10 20H14C17.7712 20 19.6569 20 20.8284 18.8284C21.4816 18.1752 21.7706 17.3001 21.8985 16"
                  stroke="#02a388"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
                <path
                  d="M10 16H6"
                  stroke="#02a388"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
                <path
                  d="M14 16H12.5"
                  stroke="#02a388"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
                <path
                  d="M2 10L7 10M22 10L11 10"
                  stroke="#02a388"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
              </svg>
            </template>
          </div>
          <!-- select cashfunction -->
          <div v-else-if="page == 'insufficient'">
            <div class="flex justify-between items-center mb-5">
              <div class="mt-3 text-center sm:mt-0 sm:text-left">
                <h3
                  class="modal-title text-lg leading-6 font-bold text-2xl"
                  id="modal-headline"
                >
                  <div>
                    {{
                      $t(
                        "translations.components.modal-wallet-payment.insufficient-funds"
                      )
                    }}
                  </div>
                  <div class="text-sm font-normal opacity-50 mt-3">
                    {{
                      $t(
                        "translations.components.modal-wallet-payment.card-number"
                      )
                    }}: {{ card.barcode }}
                  </div>
                </h3>
                <div class="mt-5 mt-3 text-lg font-normal">
                  <div>
                    {{
                      $t(
                        "translations.components.modal-wallet-payment.question"
                      )
                    }}
                  </div>
                </div>
                <div class="text-sm font-normal opacity-50 mt-5">
                  <div>
                    {{
                      $t(
                        "translations.components.modal-wallet-payment.current-balance"
                      )
                    }}: <currency />{{ vueNumberFormat(card.balance) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- confirm cashfunction -->
          <div v-else>
            <div class="flex justify-between items-center mb-5">
              <div class="mt-3 text-center sm:mt-0 sm:text-left">
                <h3
                  class="modal-title text-lg leading-6 font-bold text-2xl"
                  id="modal-headline"
                >
                  {{
                    $t(
                      "translations.components.modal-wallet-payment.card-number"
                    )
                  }}: {{ card.barcode }}
                </h3>
                <div class="text-2xl">
                  {{
                    $t(
                      "translations.components.modal-wallet-payment.successfully-paid"
                    )
                  }}:
                </div>
                <div class="text-4xl font-bold"><currency />{{ amount }}</div>
                <div class="text-sm opacity-50 mt-5">
                  <div>
                    {{
                      $t(
                        "translations.components.modal-wallet-payment.balance"
                      )
                    }}: <currency />{{ vueNumberFormat(card.balance) }}
                  </div>
                  <!-- <div class="font-bold">Nieuw saldo: <currency/>{{ vueNumberFormat(card.attributes.walletbalance - amount) }}</div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer p-4 sm:px-6 sm:flex sm:flex-row-reverse gap-5">
          <span class="mt-3 flex w-full rounded-sm shadow-sm sm:mt-0 sm:w-auto">
            <a
              v-if="page == 'scancardnumber'"
              @click="checkCardnumber()"
              class="button button-large button-filled"
            >
              {{ $t("translations.components.modal-wallet-payment.confirm") }}
            </a>
            <a
              v-if="page == 'insufficient'"
              @click="doPartialPayment()"
              class="button button-large button-ghost"
            >
              {{
                $t(
                  "translations.components.modal-wallet-payment.yes-partial-payment"
                )
              }}
            </a>
          </span>
          <span class="mt-3 flex w-full rounded-sm shadow-sm sm:mt-0 sm:w-auto">
            <a
              v-if="page == 'scancardnumber' || page == 'insufficient'"
              @click="close()"
              class="button button-large button-danger"
            >
              {{ $t("translations.components.modal-wallet-payment.close") }}
            </a>
            <a
              v-else
              @click="submit()"
              class="button button-large button-ghost"
            >
              {{ $t("translations.components.modal-wallet-payment.close") }}
            </a>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"
// mixins
import BarcodeReader from "../mixins/barcode.reader.js"
import NfcReader from "../mixins/nfc.reader.js"
import ModalClose from "./ModalClose"
// components
import Numpad from "./Numpad"

export default {
  name: "ModalWalletAddCredit",
  mixins: [BarcodeReader, NfcReader],
  emits: ["confirmed"],
  components: {
    ModalClose,
    Numpad,
  },
  data() {
    return {
      active: false,
      page: "scancardnumber",
      card: null,
      card_scan_type: "cardnumber",
      cardnumber: null,
      amount: null,
      paymenttype: null,
    }
  },

  methods: {
    async open(amount, paymenttype) {
      console.log(amount)
      this.amount = amount
      this.paymenttype = paymenttype

      this.card_scan_type = this.terminal.wallettoken_default_scan

      // disable item barcodescanner
      this.$store.dispatch("settings/itemscanner", false)

      this.page = "scancardnumber"
      this.active = true
      this.card = null
      this.cardnumber = ""

      if (this.card_scan_type === "nfc") {
        await this.readNFC()
      }
    },

    async close() {
      // activate item barcodescanner
      this.$store.dispatch("settings/itemscanner", false)

      if (this.card_scan_type === "nfc") {
        await this.abortNFC()
      }

      this.active = false
    },

    // search item on barcode scanner
    barcodeScanned(barcode) {
      if (barcode.length > 0) {
        this.cardnumber = barcode

        this.checkCardnumber()
      }
    },

    async checkCardnumber() {
      if (this.cardnumber.length > 0) {
        let data = {
          transaction_id: this.$store.getters["transaction/id"],
          amount: this.amount,
          location_id: this.location?.id ?? null,
        }

        switch (this.card_scan_type) {
          case "cardnumber":
            data.barcode = this.cardnumber
            break
          case "chipcard_id":
            data.chipcard_id = this.cardnumber
            break
          case "nfc":
            data.chipcard_id = this.cardnumber
            break
          default:
            data.cardnumber = this.cardnumber
            break
        }

        // todo loading spinner
        await axios
          .post("/wallet/token/payment", data)
          .then((response) => {
            if (response.data.result == "success") {
              this.card = {
                id: response.data.wallettoken_id,
                barcode: response.data.wallettoken_barcode,
                balance: response.data.balance,
              }
              this.page = "confirmation"
              this.$store.dispatch(
                "payment/setMutationId",
                response.data.mutation_id
              )

              if (response.data.paymenttype_id) {
                this.paymenttype.id = response.data.paymenttype_id
              }
            } else {
              if (response.data.message == "insufficient balance") {
                console.log("insufficient", response)
                this.card = {
                  id: response.data.wallettoken_id,
                  barcode: response.data.wallettoken_barcode,
                  balance: response.data.balance,
                }
                this.page = "insufficient"
              } else {
                this.$notify(
                  {
                    group: "notifications",
                    type: "error",
                    title: "Ongeldig",
                    text: response.data.message,
                  },
                  2000
                )
              }
            }
          })
          .catch((error) => {
            if (error.response.status == 404) {
              this.$notify(
                {
                  group: "notifications",
                  type: "error",
                  title: "Niet gevonden",
                  text: "Gescande artikel is niet gevonden",
                },
                2000
              )
            } else {
              this.$notify(
                {
                  group: "notifications",
                  type: "error",
                  title: "Fout opgetreden",
                  text: "Oeps er ging iets fout..",
                },
                2000
              )
            }
          })
      }
    },

    doPartialPayment() {
      this.amount = this.card.balance
      this.checkCardnumber()
    },

    submit() {
      console.log(this.paymenttype)

      this.$emit("confirmed", {
        paymenttype: this.paymenttype,
        amount: this.amount,
      })

      this.close()
    },
  },
  computed: {
    terminal() {
      return this.$store.getters["terminal/terminal"]
    },
    location() {
      return this.$store.getters["location/location"]
    },
  },
}
</script>
